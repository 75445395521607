<template>
  <div>
    <div :class="{ container: $screen.breakpoint != 'sm' }" class="mb-5" v-if="!loading">
      <div class="d-flex flex-wrap justify-content-between my-3">
        <h4 v-if="de !== null" class="fw-bold">
          Bienvenue <span class="text-primary">{{ de.nom }} {{ de.prenom }}</span
          >.
        </h4>
      </div>
      <PMessage severity="warn" v-if="de.tauxRemplissageProfil == null">
        <div class="d justify-content-between">
          Vous n'avez pas démarré le remplissage de votre CV.
        </div>
      </PMessage>
      <PMessage severity="warn" v-if="de.tauxRemplissageProfil < 70">
        <div class="d justify-content-between">
          Vous n'avez pas encore fini de remplir votre CV
        </div>
      </PMessage>
      <div
        v-if="de.tauxRemplissageProfil != null"
        class="d-flex flex-column align-items-center justify-content-center bg-light pa-5 ma-5 text-muted text-center"
        style="min-height: 40vh"
      >
        <!-- <PProgressBar :value="tauxRemplissageProfil" /> -->
        <div v-if="tauxRemplissageProfil == 0">
          <div class="mb-3 text-muted">Vous n'avez pas encore constitué votre cv.</div>
          <a @click="gotoCvEdit" class="btn btn-outline-primary">Contituer mon dossier</a>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center text-muted justify-content-center pt-4"
        >
          <h5>Votre CV est rempli à un taux de {{ tauxRemplissageProfil }}.</h5>
          <PKnob
            :min="0"
            :max="100"
            :size="200"
            v-model="tauxRemplissageProfil"
            valueTemplate="{value}%"
          />
          <div v-if="tauxRemplissageProfil < 75" class="d-flex justify-content-center">
            Vous n'avez pas encore fini de remplir votre CV. <br />
            Nous vous invitons à finaliser le remplissage de votre CV afin que vous.
            <br />
          </div>
          <div class="mb-5">
            <a
              v-if="tauxRemplissageProfil < 75"
              @click="gotoCvEdit"
              class="btn btn-outline-primary mt-4"
              >Continuer le remplissage de mon CV</a
            >
            <a v-else @click="gotoCvDetail" class="btn btn-outline-primary mt-4"
              >Consulter mon CV</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="field col-12 md:col-6 bg-white m-5">
        Chargement en cours ...
        <PSkeleton class="mb-2"></PSkeleton>
        <PSkeleton width="10rem" class="mb-2"></PSkeleton>
        <PSkeleton width="5rem" class="mb-2"></PSkeleton>
        <PSkeleton height="2rem" class="mb-2"></PSkeleton>
        <PSkeleton width="10rem" height="4rem"></PSkeleton>
      </div>
    </div>
  </div>
</template>

<script>
import DeStatistic from "../../../components/espace/demandeur/statistic/DeStatistic.vue";
import { mapActions } from "vuex";

export default {
  components: { DeStatistic },
  data() {
    return {
      demandeur: {},
      loading: false,
    };
  },
  created() {
    this.loading = true;
    this.fetchMonDossier().then((de) => {
      this.demandeur = { ...de };
      this.loading = false;
    });
  },
  computed: {
    tauxRemplissageProfil() {
      return this.de.tauxRemplissageProfil || 0;
    },
    de() {
      return this.demandeur;
    },
  },
  methods: {
    ...mapActions({
      fetchMonDossier: "demandeur/fetchMonDossier",
    }),
    gotoCvEdit() {
      this.$router.push({ name: "espace.de.cv.edit" });
    },
    gotoCvDetail() {
      this.$router.push({ name: "espace.de.moncv" });
    },
  },
};
</script>

<style scoped></style>
